import React, {Component} from 'react';
import NavBar from './components/navbar/NavBarPropertyInvestment';
import Footer from './components/Footer';

class HomeOne extends Component{
    render(){
        
        return(
            <div>
                
                {/* Navigation bar */}
                <NavBar/>
                <Footer/>
        

            </div>
        )
    }
}


export default HomeOne;