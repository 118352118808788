import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Sidebar from './components/Sidebar';
import ServiceGallery from './components/ServiceGallery';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
class ServiceDetailsLeftSidebar extends Component{
    render(){
        return(
            <div>
                {/* Navigation bar */}
                <NavBar/>

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Service Details</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/services`}>Services</a></li>
                                        <li>Service Details</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--120">
                    {/*Service section start*/}
                    <div className="service-section">
                        <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-12 order-1 order-lg-2">
                            <div className="service-details">
                                {/* service gallery */}
                                <ServiceGallery/>

                                <div className="content section-space--top--30">
                                <div className="row">
                                    <div className="col-12">
                                    <h2>Construction</h2>
                                    <p>The complexity of the modern building process demands qualified professionals, with special abilities to analyse, clearly identify challenges, and provide solutions as the project develops. <br></br>Rem Mams Construction and Projects can build</p>
                                    <p>•	Bulk sewer lines and water reticulation systems
                                    <br></br>
                                        •	Large scale residential and commercial developments
                                    <br></br>
                                        •	Bridges, wastewater treatment plants, and dams
                                    <br></br>
                                        •	High rise and multi-story buildings.
                                    </p>
                                    </div>
                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Pipe jacking</h3>
                                    <p>Rem Mams Construction and Projects has the expertise and capacity to provide clients with all the necessary services needed to prepare a site before actual construction. Our demolition and excavation division uses heavy equipment to perform earthmoving and land improvement projects. We are equipped to do site grading, ditching, drainage, land clearing, add topsoil and fill dirt, wrecking, and demolition for residential, commercial, and industrial projects.</p>
                                    </div>
                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Existing Condition Surveys</h3>
                                    <p>We have qualified personnel who will assist consulting engineers and clients to survey the conditions of the site and recommend measures that must be taken into consideration before the actual construction takes place. This service is intended to assist clients to avoid complicated pitfalls, which have the potential of delaying the project and causing financial losses for clients.</p>
                                    </div>
                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Specialist Concrete Capability</h3>
                                    <p>Rem Mams Construction and Projects has extensive experience in the specialised placing of structural concrete for intricate and sophisticated designs. This is a key aspect in the successful construction of any major structure, of which concrete is a crucial design element. In many major projects where concrete is the major structural element, safety and quality are paramount for the long-term use of that structure.</p>
                                    </div>
                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Renovations and Remodelling</h3>
                                    <p>Rem Mams Construction and Projects understands the complexities that accompany the renovation or remodeling of major infrastructure. Our team of professionals has the necessary skills to guide and assist clients on any major renovations requiring the services of a general contractor. This also extends to any maintenance works that may be required.</p>
                                    </div>

     <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Program Management</h3>
                                    <p>At Rem Mams construction and Project, we always aim to create a close partnership with the client during the early development stage of their project, so we can fully understand the scope of the project beyond the construction phase. This type of project delivery method, generally results in expeditious schedules, lower costs of design and construction, and less contractor-initiated change requests, due to document design errors and omissions. Through a rich success history, Rem Mams Construction and Projects has provided construction management services and demonstrated an understanding of clients’ goals and needs. We have clear policies on program management services which guide:
{/* <br></br>
•	Responsible project team management
<br></br>
•	Prudent selection of qualified subcontractors and suppliers
<br></br>
•	The mechanism for owner/architect approval of materials, colours, and systems
<br></br>
•	Communications liaison linking client, designers, lenders, and inspectors
<br></br>
•	Cost and schedule controls
<br></br>
•	Quality control
<br></br>
•	Safety management
<br></br>
•	Site inspections
<br></br>
•	Record keeping
<br></br>
•	Post-construction services*/}
</p> 
                                    </div>

                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Research and Estimation</h3>
                                    <p>Rem Mams Construction and Projects provides research and estimation services through a platform that delivers accurate estimating, import, and question-based estimating tools. Our research and estimation services provide clients with a realistic guide on project cost.</p>
                                    </div>

                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Research and Estimation</h3>

                                                                       <p>At Rem Mams construction and Project, we always aim to create a close partnership with the client during the early development stage of their project, so we can fully understand the scope of the project beyond the construction phase. This type of project delivery method, generally results in expeditious schedules, lower costs of design and construction, and less contractor-initiated change requests, due to document design errors and omissions. Through a rich success history, Rem Mams Construction and Projects has provided construction management services and demonstrated an understanding of clients’ goals and needs. We have clear policies on program management services which guide:
{/* <br></br>
•	Responsible project team management
<br></br>
•	Prudent selection of qualified subcontractors and suppliers
<br></br>
•	The mechanism for owner/architect approval of materials, colours, and systems
<br></br>
•	Communications liaison linking client, designers, lenders, and inspectors
<br></br>
•	Cost and schedule controls
<br></br>
•	Quality control
<br></br>
•	Safety management
<br></br>
•	Site inspections
<br></br>
•	Record keeping
<br></br>
•	Post-construction services */}

</p>
                                    </div>


                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Research and Estimation</h3>

                                                                       <p>

•	Responsible project team management
<br></br>
•	Prudent selection of qualified subcontractors and suppliers
<br></br>
•	The mechanism for owner/architect approval of materials, colours, and systems
<br></br>
•	Communications liaison linking client, designers, lenders, and inspectors
<br></br>
•	Cost and schedule controls
<br></br>
•	Quality control
<br></br>
•	Safety management
<br></br>
•	Site inspections
<br></br>
•	Record keeping
<br></br>
•	Post-construction services

</p>
                                    </div>
                                   


                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Our company</h3>
                                  
                                    <p>The company will rejuvenate the old non-operational wastewater treatment plant by
<br></br>
•	Installing a new head of works
<br></br>
•	Repairing the pump-station
<br></br>
•	Repairing the BIO Filter
<br></br>
•	Fixing the primary and secondary primary clarifiers
<br></br>
•	Installing a 1500 mega-litre packaged plant
<br></br>
•	Revitalising old drying beds as there is a need for new beds and chlorination ponds.
<br></br>



</p>
                                    </div>


                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Road and Highway Construction</h3>
                                  
                                    <p>

•	The repair to all concrete works, including structural and maintenance works
<br></br>
•	Maintenance refurbishment of all bridge bearings
<br></br>
•	Removal and replacement of Parapet rails with pre-cast concrete elements
<br></br>
•	The reinstatement and upgrading of the existing stormwater drainage system
<br></br>
•	Reinstatement and upgrading of the stormwater inlet system
<br></br>
•	Replacement of the Asphalt works
<br></br>
•	Replacement of all Bridge joints.



</p>
                                    </div>


                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Roads Infrastructure</h3>

                                                                       <p>
                                                                     
•	Structures and road design
<br></br>
•	Road and highway construction
<br></br>
•	Surface dressing
<br></br>
•	Asphalt and concrete paving
<br></br>
•	Pothole patching
<br></br>
•	General road and highway maintenance
<br></br>
•	Road marking and signs

</p>
                                    </div>
                                   


                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Pipelines</h3>

                                                                       <p>Bulk Sewer Pipeline Phase 
                                                                     

<br></br>
The 5 km pipeline starts on the boundary of Hammanskraal Extension 2 and discharges into the municipality’s wastewater treatment works at Temba Babalegi. The pipeline has been designed to handle a peak flow rate of more than 600 litres per second, the diameter of the pipeline varies from 50 

</p>
                                    </div>

                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4 col-12 order-2 order-lg-1">
                                <Sidebar />
                            </div>
                        </div>
                        </div>
                    </div>
                    {/*Service section end*/}
                    </div>

                {/* Brand logo */}
                <BrandLogoSlider background = "grey-bg" />

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default ServiceDetailsLeftSidebar;