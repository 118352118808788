import React, {Component} from 'react';
import NavBar from './components/navbar/NavBarxPropertyDevelopment';
import Footer from './components/Footer';


class HomeOne extends Component{
    render(){
        
        return(
            <div>
                
                {/* Navigation bar */}
                <NavBar/>
                
            
              
                {/* Footer */}
                <Footer/>

          

            </div>
        )
    }
}


export default HomeOne;