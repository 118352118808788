import React, {Component} from 'react';

import Footer from './components/Footer';
import MobileMenu from './components/MobileMenu';

class HomeOne extends Component{
    render(){
        
        return(
            <div>
                
                {/* Navigation bar */}
                { /*=======  header top bar  =======*/ } 
            <div className = "header-top-bar">
            <div className = "container">
            <div className = "row align-items-center">
            <div className = "col-lg-4"> 
            { /* top bar left */ } 
            <div className = "top-bar-left-wrapper">
            <div className = "social-links social-links--white-topbar d-inline-block">
            <ul>
            <li> <a href = "//facebook.com" > < i className = "zmdi zmdi-facebook" /> </a></li >
            <li> <a href = "//twitter.com" > < i className = "zmdi zmdi-twitter" /> </a></li >
            <li> <a href = "//vimeo.com" > < i className = "zmdi zmdi-vimeo" /> </a></li >
            <li> <a href = "//linkedin.com" > < i className = "zmdi zmdi-linkedin-box" /> </a></li >
            <li> <a href = "//skype.com" > < i className = "zmdi zmdi-skype" /> </a></li >
            </ul> 
            </div> 
            </div> 
            </div>
             <div className = "col-lg-8" > 
             { /* top bar right */ } 
             <div className = "top-bar-right-wrapper" >
            <a href = { `${process.env.PUBLIC_URL}/contact-us` }
            className = "ht-btn ht-btn--default d-inline-block" > GET A QUOTE </a> 
            </div> 
            </div> 
            </div>
             </div> 
             </div>
              { /*=======  End of header top bar  =======*/ } { /*=======  header info area  =======*/ } 
              <div className = "header-info-area" >
            <div className = "container" >
            <div className = "row align-items-center" >
            <div className = "col-lg-12" >
            <div className = "header-info-wrapper align-items-center" > { /* logo */ } 
            <div className = "logo" >
            <a href = { `${process.env.PUBLIC_URL}/` } >
            <img src = "assets/img/logo/logo.png"
            className = "img-fluid"
            alt = "Logo" />
            </a> 
            </div> { /* header contact info */ } 
            { /* mobile menu */ } 
           
            </div> 
            </div>
             </div>
              </div>
               </div> { /*=======  End of header info area =======*/ } { /*=======  header navigation area  =======*/ } 
                
                {/* Hero slider */}
                {/* <HeroSliderOne/> */}
                
              <h1>Under Development</h1>
                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default HomeOne;