import React, { Component } from 'react';

class FeatureIcon extends Component{
    render(){

        let data = [
            {featureIcon: "feature-3.png", featureTitle: "Our Philosophy", featureDescription: "We strive to establish long-term relationships with our clients, built on trust, whilst focusing on enhancing productivity and efficiency. We are committed to our Social Investment program, with the main focus on assisting small businesses run by women and the youth. "},
     
            {featureIcon: "feature-1.png", featureTitle: "Our Vision", featureDescription: "To expand our current market share in the infrastructure development industry by owning and operating vital components of the construction industry supply chain in a profitable, growth-oriented manner "},
            {featureIcon: "feature-2.png", featureTitle: "Our Mission", featureDescription: "To execute strategic infrastructure development projects, to contribute to the upliftment and development of the communities we serve."},
            {featureIcon: "feature-3.png", featureTitle: "Our Philosophy", featureDescription: "Recognising the fact that we operate in a global village that is an ever-changing and dynamic environment, the company has adopted a strategy to ensure alliances and partnerships with local companies of excellent reputation. This ensures the transfer of skills to local communities."}, 
        ];

        let Datalist = data.map((val, i)=>{
            return(
                <div className="col-lg-4 col-md-6 col-12 section-space--bottom--30" key={i}>
                    <div className="feature">
                    <div className="icon">
                        <img src={`assets/img/icons/${val.featureIcon}`} className="img-fluid" alt="" />
                    </div>
                    <div className="content">
                        <h3>{val.featureTitle}</h3>
                        <p>{val.featureDescription}</p>
                    </div>
                    </div>
                </div>
            )
        });

        return(
            <div>
                {/*Feature section start*/}
                <div className={`feature-section section-space--inner--100 ${this.props.background}`}>
                    <div className="container">
                        <div className="col-lg-12">
                            <div className="feature-item-wrapper">
                                <div className="row">
                                    {Datalist}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Feature section end*/}
            </div>
        )
    }
}

export default FeatureIcon;