import React, {Component} from 'react';
import NavBar from './components/navbar/NavBarSecurity';
import Footer from './components/Footer';

class HomeOne extends Component{
    render(){
        
        return(
            <div>
                
                {/* Navigation bar */}
                <NavBar/>
                
                {/* Hero slider */}
                <Footer/>
              
            </div>
        )
    }
}


export default HomeOne;