import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
class Projects extends Component{
    render(){

        let data = [
            {pageLink: 'project-details', img: 'project-1.jpg', projectTitle: 'Health and Safety', projectSubtitle: 'ZERO HARM!!! This is the foundation of the company’s approach to occupational health. The company does not want any employees to get injured or contract an illness while working for the company. The construction industry, by nature, poses many health risks to employees, and the business is pro-active in preventing any of these injuries or illnesses.'},
            {pageLink: 'project-details', img: 'project-2.jpg', projectTitle: 'Environment', projectSubtitle: 'We recognise the value and scarcity of the country’s natural resources, especially water, and are mindful of the impact of our neighbours and what we leave behind when our operations close on site.'},
            {pageLink: 'project-details', img: 'project-5.jpg', projectTitle: 'Staff', projectSubtitle: 'Recognising the fact that we operate in a global village and an ever-changing and dynamic built environment, the company has adopted a strategy to ensure alliances and partnerships with local companies of excellent reputation. This ensures the transfer of skills to local communities.'},
            {pageLink: 'project-details', img: 'project-3.jpg', projectTitle: 'Staff and Training', projectSubtitle: 'Our most valuable asset at Rem Mams Construction and Project is our staff. They form the lifeblood and backbone of our operations. As a truly South African company, we support and drive transformation. We are proud of our staff, of which 60% are historically disadvantaged individuals.'},
            {pageLink: 'project-details', img: 'project-4.jpg', projectTitle: 'Training and Education', projectSubtitle: 'The company has set up a special education fund for employees. The fund will finance previously disadvantaged individuals working for the company to embark on further training to up-skill and enhance their productivity.'},
            {pageLink: 'project-details', img: 'project-6.jpg', projectTitle: 'Community', projectSubtitle: 'Whenever the company is engaged in the development of infrastructure it seeks to provide training, development, and employment to as many people from the recipient community as possible. '},
            {pageLink: 'project-details', img: 'project-7.jpg', projectTitle: 'Community', projectSubtitle: 'The wage payable will be determined by the applicable legislation or Bargaining Council Agreement.'}

        ];

        let Datalist = data.map((val, i)=> {
            return(
                <div className="col-lg-4 col-sm-6 col-12 section-space--bottom--30" key={i}>
                    <div className="service-grid-item service-grid-item--style2">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}>
                            <img src={`assets/img/projects/${val.img}`} className="img-fluid" alt="" />
                        </a>
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <h3 className="title">
                        <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}>{val.projectTitle}</a>
                        </h3>
                        <p className="subtitle">{val.projectSubtitle}</p>
                        <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`} className="see-more-link">SEE MORE</a>
                    </div>
                    </div>
                </div>
            )
        });

        return(
            <div>
                {/* Navigation bar */}
                <NavBar/>

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Project</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>Project</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                {/*====================  project page content ====================*/}
                <div className="page-wrapper section-space--inner--120">
                    {/*Projects section start*/}
                    <div className="project-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="project-item-wrapper">
                                        <div className="row">
                                            {Datalist}
                                        </div>
                                    </div>
                                </div>
                            </div>
                         
                        </div>
                    </div>
                    {/*Projects section end*/}
                </div>

                {/*====================  End of project page content  ====================*/}

                
                {/* Brand logo */}
                <BrandLogoSlider background = "grey-bg" />

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>
            </div>
        )
    }
}


export default Projects;