import React, {Component} from 'react';
import NavBar from './components/navbar/NavBarConstruction';
import Footer from './components/Footer';
import HeroSliderTwo from './components/HeroSliderOne';
import MobileMenu from './components/MobileMenu';

class HomeOne extends Component{
    render(){
        
        return(
            <div>
                
                {/* Navigation bar */}
                <NavBar/>
                
             
                <HeroSliderTwo/>
              
                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default HomeOne;