import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import FeatureIcon from '../components/FeatureIcon';

import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';

class About extends Component{

    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
      }
     
      openModal () {
        this.setState({isOpen: true})
      }

    render(){
        return(
            <div>

                {/* Navigation bar */}
                <NavBar/>
                
                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>About Us</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>About Us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--top--120">
                {/*About section start*/}
                <div className="about-section section-space--inner--bottom--120">
                <div className="container">
                    <div className="row row-25 align-items-center">
                    <div className="col-lg-6 col-12 mb-30">
                        <div className="about-image-two">
                        <img src="assets/img/about/about-3.jpg" alt="" />
                            {/* <span className="video-popup">
                                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='OrS-93U4AYQ' onClose={() => this.setState({isOpen: false})} />
                                <button onClick={this.openModal}>
                                    <i className="ion-ios-play-outline" />
                                </button>
                            </span> */}
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 mb-30">
                        <div className="about-content-two">
                        <h3>Welcome to Rem Mams</h3>
                        <h1>10+ Years of Experience in Industry</h1>
                        <h4>We are ready to build your dream home.</h4>
                        <p>Rem Mams Construction is a subsidiary of REM Mams group which is a South African based design and construction company involved in the development of large scale infrastructure. Rem Mams Construction and Projects has established itself as one of the leading black emerging construction companies within the South African market. The company has qualified professionals with broad experience in areas of building, mass earthworks, pipelines, waterworks, roads, and concrete works.</p>
                        <a href="services" className="ht-btn--default ht-btn--default--dark-hover section-space--top--20">Our Services</a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*About section end*/}
                
                {/* Feature Icon */}
                <FeatureIcon background = "grey-bg"/>

                {/*About section start*/}
                <div className="about-section section-space--inner--120">
                <div className="container">
                    <div className="about-wrapper row">
                    <div className="col-sm-6 col-12 order-1 order-lg-2">
                        <div className="about-image about-image-1">
                        <img src="assets/img/about/about-1.jpg" alt="" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-12 order-2 order-lg-3">
                        <div className="about-image about-image-2">
                        <img src="assets/img/about/about-2.jpg" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 order-3 order-lg-1">
                        <div className="about-content about-content-1">
                        <h1><span>10+</span>Years of Experience</h1>
                        <p>We comply with regulatory and statutory requirements and have the following accreditations:
                        <br></br>
                        •	CIDB rating of 7 GB , 7CE 6ME,6EP and 6SQ with CRS number 257581
                        <br></br>
                        •	Registered with the National Home Builders Registration Council (NHBRC), registration number 1-191690681
                        <br></br>
                        •	Registers asbestos contractor certificate no RAC2021/0HH/064CI
                        <br></br>
                        •	Member of Agrement South Africa 2008/232
                        <br></br>
                        •   Borehole water association of Southern Africa 799
                        <br></br>
                        •   Member of the southern African light steel frame building association  LSF209
                        <br></br>
                        •   Member of national pool and spa association NSPI
                        <br></br>
                        •   BCCEI BCI7320792831
                        <br></br>
                        •   Waste Transporter GPT-09-739
                        <br></br>
                        •   ISO 9001:2015 Certification
                        <br></br>
                        •   BEE Level 1
                        <br></br>
                        <br></br>
                      
                        </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 order-4">
                        <div className="about-content about-content-2">
                        <p>Rem Mams Construction and Projects  has developed a Management Strategy that enables the following
                        <br></br>
                        •	Improved project value through knowledge management
                        <br></br>
                        •	Continuous improvement through lessons learned
                        <br></br>
                        •	Resource efficiency optimisation
                        <br></br>
                        •	Systems thinking
                        <br></br></p>
                        <a href={`${process.env.PUBLIC_URL}/contact-us`} className="ht-btn--default ht-btn--default--dark-hover section-space--top--20">Contact us</a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*About section end*/}
                
             
           
                {/* Brand logo */}
                <BrandLogoSlider background = "" />

                </div>


                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default About;