import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
class Services extends Component{
    render(){

        let data = [
            {pageLink: 'service-details-left-sidebar', img: 'service1.jpg', iconClass: 'flaticon-002-welding', serviceTitle: 'General Contracting', serviceSubtitle: 'Rem Mams Construction and Projects have a CIDB rating of 7CE and  7GB which has been achieved through the delivery of major projects built by an experienced professional team that adheres to the highest building standards'},
            {pageLink: 'service-details-left-sidebar', img: 'service2.jpg', iconClass: 'flaticon-004-walkie-talkie', serviceTitle: 'Roads Infrastructure', serviceSubtitle: 'Rem Mams Construction and Projects has the skills and capacity to undertake any road construction or repair projects fit for a CIDB level construction company.'},
            {pageLink: 'service-details-left-sidebar', img: 'service3.jpg', iconClass: 'flaticon-015-cart', serviceTitle: 'Road & Highway Construction', serviceSubtitle: 'On the M1 project, the existing asphalt wearing course was replaced with new asphalt surfacing on an urban section of a major national highway. The work included the replacement of existing bridge joints with new Thorma joints.'},
            
            {pageLink: 'service-details-left-sidebar', img: 'service3a.jpg', iconClass: 'flaticon-015-cart', serviceTitle: 'Road & Highway Construction', serviceSubtitle: 'Due to extremely high traffic volumes during the daytime, the majority of the work had to be executed at night. '},
            {pageLink: 'service-details-left-sidebar', img: 'service3b.jpg', iconClass: 'flaticon-015-cart', serviceTitle: 'Road & Highway Construction', serviceSubtitle: 'The scope also included the upgrading of the road/bridge drainage system, as well as the replacement of existing steel handrails on the bridge with concrete parapet sections. '},
            {pageLink: 'service-details-left-sidebar', img: 'service3c.jpg', iconClass: 'flaticon-015-cart', serviceTitle: 'Road & Highway Construction', serviceSubtitle: 'Road-markings were reinstated and the safety of the road was upgraded through the addition of cat eyes and refurbishment of crash barriers.'},
            {pageLink: 'service-details-left-sidebar', img: 'service-3.jpg', iconClass: 'flaticon-010-tank-1', serviceTitle: 'Water Utilities Corporation', serviceSubtitle: 'General requirements for assessment, supply, delivery, and installation of mechanical, civil, electrical, control equipment, instrumentation equipment, actuators, associated accessories, and documentation (drawings and manuals).'},
            {pageLink: 'service-details-left-sidebar', img: 'service-3a.jpg', iconClass: 'flaticon-010-tank-1', serviceTitle: 'Water Utilities Corporation', serviceSubtitle: 'All equipment in the pump stations, including modification of the suction and delivery pipework and construction of a building to house variable speed drive units.'},
            {pageLink: 'service-details-left-sidebar', img: 'service-3b.jpg', iconClass: 'flaticon-010-tank-1', serviceTitle: 'Water Utilities Corporation', serviceSubtitle: 'The second requirement is for the scheme SCADA upgrade, engineering, and configuration to cater for all changes which have been effected at the pump station to allow for monitoring, control, data acquisition, and automation to enhance the operational efficiency of the scheme.'},
            {pageLink: 'service-details-left-sidebar', img: 'service-2.jpg', iconClass: 'flaticon-004-walkie-talkie', serviceTitle: 'Laying of pipeline ', serviceSubtitle: 'Rem Mams Construction and Projects revitalise all the old plumbing as well as install a new water conditioning plant to purify the hospital borehole water'},
            {pageLink: 'service-details-left-sidebar', img: 'service3d.jpeg', iconClass: 'flaticon-004-walkie-talkie', serviceTitle: 'Asbestos Removal ', serviceSubtitle: ' Identifying Asbestos, Asbestos identification, Asbestos Sampling and Testing ,Asbestos Removal, Asbestos Encapsulation, Roofing & Re-roofing'},

        ];

        let Datalist = data.map((val, i) => {
            return(
                <div className="col-lg-4 col-md-6 col-12 section-space--bottom--30" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}>
                            <img src={`assets/img/service/${val.img}`} className="img-fluid" alt="Service Grid" />
                        </a>
                        </div>
                        <div className="icon">
                        <i className={val.iconClass} />
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <h3 className="title">
                        <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}>{val.serviceTitle}</a>
                        </h3>
                        <p className="subtitle">{val.serviceSubtitle}</p>
                        {/* <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`} className="see-more-link">SEE MORE</a> */}
                    </div>
                    </div>
                </div>
            )
        });

        return(
            <div>

                {/* Navigation bar */}
                <NavBar/>

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Service</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>Service</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                {/*====================  service page content ====================*/}
                <div className="page-wrapper section-space--inner--120">
                {/*Service section start*/}
                <div className="service-section">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="service-item-wrapper">
                            <div className="row">
                                {Datalist}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/*Service section end*/}
                </div>

                {/*====================  End of service page content  ====================*/}

                {/* Brand logo */}
                <BrandLogoSlider background = "grey-bg" />

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}

export default Services;