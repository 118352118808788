import React, { Component } from 'react';


class Demo extends Component{
    render(){

      

        return(
            <div>
                <div className="landing-page-wrapper">
                    {/* Start Header Section */}
                    <div className="header-section section sticker">
                        <div className="container">
                        <div className="row justify-content-between align-items-center">
                            {/* Logo */}
                            <div className="logo col-6 text-left">
                            <a href="/"><img src="assets/img/landing/logo-light.png" className="img-fluid" alt="" /><img src="assets/img/landing/logo.png" alt="" className="sticky-logo img-fluid" /></a>
                            </div>
                            {/* Logo */}
                            <div className="col-6 text-right">
                            <a className="buy-btn" href={ `${process.env.PUBLIC_URL}/contact-us` }>Contact us</a>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    
                    {/* Header Section End */}
                    {/* Start Hero Section */}
                    <div className="hero-section section overlay landing-hero-bg">
                        <div className="container">
                        <div className="row">
                            <div className="hero-content text-center col-12">
                            <h1><strong>REM MAMS</strong> GROUP OF COMPANIES </h1>
                            <p>We are a 100% black owned and black empowered company that is committed to the expansion and upliftment of all communities in South Africa. REM MAMS  Group is an innovative and competitive family of diverse subsidiaries specialising in providing holistic professional products and services through wealth creation and sustainable growth. Our business have a vested interest in the following sectors:</p>
                            <a className="buy-btn" href={ `${process.env.PUBLIC_URL}/home-construction` }>View Construction</a> <span></span>
                             {/* <a className="buy-btn" href={ `${process.env.PUBLIC_URL}/home-one`}>View Security</a> <span></span> */} 
                             <a className="buy-btn" href={ `${process.env.PUBLIC_URL}/home-property-investment`}>View Property Investment </a>
                             <a className="buy-btn" href={ `${process.env.PUBLIC_URL}/home-property-development`}>View Property Development </a>

                             <a className="buy-btn" href={ `${process.env.PUBLIC_URL}/home-security`}>View Security </a>
                             <a className="buy-btn" href={ `${process.env.PUBLIC_URL}/home-plant-hire`}>View  Plant Hire </a>
                             <a className="buy-btn" href={ `${process.env.PUBLIC_URL}/home-distribution`}>View Distribution </a>
                             <a className="buy-btn" href={ `${process.env.PUBLIC_URL}/home-export`}>View Export and Import </a>

                           </div> 
                            
                        </div>
                        </div>
                    </div>
                    {/* Hero Section End */}

                 
                    {/* Footer Section Start */}
                    {/* <div className="footer-section section pt-65 pb-50 overlay bg-img" data-bg="assets/img/landing/hero-bg.jpg">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1>Rem Mams Construction and Project</h1>
                                    <a className="buy-btn float-right" href={ `${process.env.PUBLIC_URL}/contact-us` }>Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* Footer Section End */}
                    {/*====================  scroll top ====================*/}
                    {/* <button className="scroll-top" id="scroll-top">
                        <i className="ion-android-arrow-up" />
                    </button> */}
                    {/*====================  End of scroll top  ====================*/}
                </div>

            </div>
        )
    }
}

export default Demo;