import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import PhotoGallery from './components/PhotoGallery';
class Projects extends Component{

    render(){
        return(
            <div>
                {/* Navigation bar */}
                <NavBar/>

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Project Details</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="projects">Project</a></li>
                                        <li>Project Details</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                {/*====================  project details page content ====================*/}
                <div className="page-wrapper section-space--inner--120">
                {/*Projects section start*/}
                <div className="project-section">
                    <div className="container">
                    <div className="row">
                        <div className="col-12 section-space--bottom--40">
                        <div className="project-image"><img src="assets/img/projects/project-details.jpg" className="img-fluid" alt="" /></div>
                        </div>
                        {/* <div className="col-lg-4 col-12 section-space--bottom--30">
                            <div className="project-information">
                                <h3>Value Statement</h3>
                                <ul>
                                    <li><strong>Client:</strong> <a href="project-details">RRS Company</a></li>
                                    <li><strong>Location:</strong> San Francisco</li>
                                    <li><strong>Area(sf):</strong> 550,000 sf</li>
                                    <li><strong>Year:</strong> 2019</li>
                                    <li><strong>Budget:</strong> $245000000</li>
                                    <li><strong>Architect:</strong> Scott &amp; Austin</li>
                                    <li><strong>Sector:</strong> <a href="project-details">Tunnel</a>, <a href="project-details">Transport</a></li>
                                </ul>
                            </div>
                        </div> */}
                        <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                            <div className="project-details">
                                <h2>Value Statement</h2>
                                <br></br>
                                •	<b>S</b>ervice-oriented approach. Providing our clients, colleagues, and partners with the necessary support to achieve our collective goals.
<br></br>
•	<b>T</b>eamwork. Through a combined effort and co-operation, we will achieve more than we could as individuals, relying on the combined talents and strengths of the team.
<br></br>
•	<b>R</b>espect for our clients, colleagues, the communities we operate in, and their diversity, as well as the environment.
<br></br>
•	<b>I</b>ntegrity in all aspects of our operations, demonstrating honesty, transparency, and accountability.
<br></br>
•	<b>V</b>alue addition in all activities we take part in, ensuring the quality of delivery and performance to the satisfaction of internal and external clients.
<br></br>
•	<b>E</b>xcellence will remain the benchmark to which we will measure our performance within each Business Unit, Department, and Project.
<br></br>
                                </div>
                        </div>
                        <div className="col-12">
                            <PhotoGallery/>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="project-section">
                    <div className="container">
                    <div className="row">
                        
                       
                        <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                            <div className="project-details">
                                <h2>Partnerships</h2>
                                <br></br>
                          <p>On-going changes in the growth and development of the company are geared to ensure that we take maximum advantage of the available opportunities in the market. We have seen a definite shift in industry trends, with a move away from traditional public sector work towards Private-Public Partnerships (PPPs). This comes about as a realization that governments, worldwide, can no longer be expected to carry the burden of infrastructure development alone, given the many competing socio-political priorities. As a company, we have realised this and we are in a position to participate in PPP opportunities.</p>
                                </div>
                        </div>
                        <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                            <div className="project-details">
                                <h2>Staff</h2>
                                <br></br>
                          <p>Our most valuable asset at Rem Mams Construction and Projects is our staff. They form the lifeblood and backbone of our operations. As a truly South African company, we support and drive transformation. We are proud of our staff, of which 60% are historically disadvantaged individuals.</p>
                                </div>
                        </div>
                        <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                            <div className="project-details">
                                <h2>Health, Safety and Environment</h2>
                                <br></br>
                          <p>Emphasis is placed on health and safety processes to ensure a safer workplace and environment for our staff.</p>
                                </div>
                        </div>
                        <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                            <div className="project-details">
                                <h2>Training and Education</h2>
                                <br></br>
                          <p>Rem Mams Construction and Projects has set-up a special education fund for employees. The fund will finance previously disadvantaged individuals working for the company to embark on further training to upskill and enhance their productivity. </p>
                                </div>
                        </div>

                        <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                            <div className="project-details">
                                <h2>Occupational Health</h2>
                                <br></br>
                          <p>Efforts to combat occupational health risks are guided by three principles;
                              <br></br>
•	We believe that all occupational illnesses are preventable
<br></br>
•	We learn by assessing and monitoring occupational disease incidence to prevent them
<br></br>
•	We apply common, simple and non-negotiable standards through Kato Civils
 </p>
                                </div>
                        </div>

                        <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                            <div className="project-details">
                                <h2>The business primarily renders its services to the following clients</h2>
                                <br></br>
                          <p>Engineering Companies
                              <br></br>
                              Construction companies
<br></br>
Property Developers
<br></br>
Architects
<br></br>
Municipalities
<br>
</br>
Government Departments
<br></br>
Private land-owners
<br>
</br>
Churches
<br></br>
Medical Institutions
 </p>
                                </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/*Projects section end*/}

                </div>

                {/*====================  End of project details page content  ====================*/}

                {/* Brand logo */}
                <BrandLogoSlider background = "grey-bg" />

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default Projects;