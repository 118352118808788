import React, {Component} from 'react';
import NavBar from './components/NavBar';
import Footer from './components/Footer';

class HomeOne extends Component{
    render(){
        
        return(
            <div>
                
                {/* Navigation bar */}
                <NavBar/>
                
                {/* Hero slider */}
             
                
              
                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
            

            </div>
        )
    }
}


export default HomeOne;