import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Sidebar from './components/Sidebar';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import ServiceGallery from './components/ServiceGalleryRight';
class ServiceDetailsRightSidebar extends Component{
    render(){

        return(
            <div>
                {/* Navigation bar */}
                <NavBar/>

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Service Details</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/services`}>Services</a></li>
                                        <li>Service Details</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--120">
                    {/*Service section start*/}
                    <div className="service-section">
                        <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-12 order-1">
                            <div className="service-details">
                                {/* service gallery */}
                                <ServiceGallery/>
                                <div className="content section-space--top--30">
                                <div className="row">
                                    <div className="col-12">
                                    <h2>Lake Malawi Water Supply Project</h2>
                                    <p>As one of the region’s leading construction company, Khato Civils, in conjunction with the South Zambezi (Consulting Engineers), has been appointed to undertake one of Malawi’s mega water supply projects, namely the Lake Malawi Water Supply Project also known as Project Rhino.</p>
                                    <p>The project will pump and deliver 100 million litres per day of potable water from Lake Malawi to Lilongwe City through a 1200mm diameter Ductile Iron Cement Mortar; Lined (DICL) pipe, alleviating problems that have affected the lifestyle and livelihoods of the city’s 2 million-plus residents and its surroundings. Valued at about $500 million, it is billed as Africa’s largest water transfer project under construction and is expected to be completed by 2019.</p>
                                    </div>
                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Project Analysis</h3>
                                    <p>The primary design and construction components that will be included in the potable water supply system will comprise of:
                                      <br></br>  
                                      •	Intake of raw water abstraction from Lake Malawi, the intake structure will be at least 500m.
                                      <br></br>
•	Intake pumps for the raw water capacity of 100 000 m3/d with two 800mm, 2km transmission pipelines.
<br></br>  
•	Pressure Sand Filtration and Clearwater tank of 4000m3
<br></br>  
•	Chlorination system for disinfection
<br></br>  
•	Clear water reservoir of 8000 m3/d and booster pump station at Lifuwu
<br></br>  
•	Clear water transmission pipeline of 1200mm diameter ductile iron pipeline of 50kms from Lifuwu to Kanyenyeva
<br></br>  
•	Clear water reservoir  5000 m3/d and booster pump station at Kanyenyeva
<br></br>  
•	Break pressure tanks of 8 000 m3 and booster pump station at Katengeza
<br></br>  
•	Clear water transmission pipeline of 1200 mm diameter ductile iron pipeline of 14km from Katengeza to Mvera
<br></br>  
•	Break pressure tanks of 8 000 m3 and booster pump station at Mvera
<br></br>  
•	Clear water transmission pipeline of 1200mm diameter ductile iron pipeline of 12kms from Mvera to Dowa Turn off.
<br></br>  
•	Clear water 1200mm diameter ductile iron gravity pipeline from Dowa Turn off to existing Kanengo reservoirs of 36kms
<br></br>  
•	Miscellaneous infrastructure for the above e.g. fences, guardrooms, access roads, stormwater, pump houses.
                                <br></br>  
                                    </p>
                                    </div>
                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Project Scope</h3>
                                    <p>The scope of works covers general requirements for assessment, supply, delivery, and installation of mechanical, civil, electrical, control equipment, instrumentation equipment, actuators, associated accessories, and documentation (drawings and manuals).<br></br>This project covers all equipment in the pump stations, including modification of the suction and delivery pipework and construction of a building to house variable speed drive units.

The second requirement is for the scheme SCADA upgrade, engineering, and configuration to cater for all changes which have been effected at the pump station to allow for monitoring, control, data acquisition, and automation to enhance the operational efficiency of the scheme.
</p>
                                    </div>
                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Project Planning</h3>
                                    <p>The Lake Malawi Water supply Project was first conceptualized as a long-term and sustainable intervention to water problems that have been affecting the ever-growing population of Lilongwe for over a decade now. Lilongwe, which is Malawi’s largest city, requires a stable water supply. The local water utility company, Lilongwe Water Board (LWB), has currently been forced to ration water supply to residences and public places continuously.
                                        <br></br>Primary components of the project include an intake tower with pumps and water treatment facilities right from the abstraction point in Senga Bay, Salima to Lilongwe, a distance of 125 km. Water treatment will be done through pressure filters with an overall plant capacity of 4166 cubic metres per hour. The filters will be twenty-two (22) horizontal air scoured pressure filters each with a capacity of 200 cubic metres per hour. Disinfection will be using gas chlorination. Rem Mams Construction and Projects will also build clear water tanks and pumping stations along the 125 km stretch, the first one at Lifuwu in Salima with a capacity of 2500 cubic metres.
                                    </p>
                                    </div>
                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Project Strategy</h3>
                                    <p>The treated water will then be conveyed from Lifuwu to Kanyenyeva, which is located 51 kilometres in the same district. At Kanyenyeva the water will be pumped to Dowa turnoff clear water tank, a distance of 21 km. From there the water will gravitate 37 km to Kanengo water tanks. It is from this stage that water will now be distributed through the existing Lilongwe Water Board’s systems to the city’s residents.

                                        <br></br>The residents in Salima and Dowa will also equally benefit from access to potable water as they fall within the stretch of the project’s catchment area.
                                        <br></br>From another perspective, the project will create over 4000 jobs and a lot more spin-off opportunities for small and medium enterprises.
                                        <br></br>The Rem Mams Construction and Projects (Pty)Ltd and South Zambezi (Pty) Limited joint venture, was awarded the contract after the Office of the Director of Public Procurement (ODPP), used the restricted tendering procurement method under International Competitive Bidding. The consortium came ahead of six other reputable global companies from China, Portugal, South Africa, Italy, and the United Kingdom.
                                    </p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4 col-12 order-2">
                                <Sidebar />
                            </div>
                        </div>
                        </div>
                    </div>
                    {/*Service section end*/}
                    </div>

                {/* Brand logo */}
                <BrandLogoSlider background = "grey-bg" />

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default ServiceDetailsRightSidebar;